'use strict';

angular.module('enervexSalesappApp').controller('FanCtrl', function($scope, Property, Fan, $stateParams, Flash, $state, _, FanCategory, Util, FileUploader) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged:false
	}
	$scope.configs = Property.configs()
	$scope.codeWarning = Util.setCodeWarning()
	$scope.itemCodeDisabled = true
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	$scope.calculationTypes = [{
		id: "equation",
		name: "Equation"
	}, {
		id: "constants",
		name: "Constants"
	}]
	$scope.export = function(mimetype) {
		var url = "/api/fans/" + $stateParams.id + "/export/" + mimetype;
		console.log("url", url)
		window.location.href = url;
	}
	$scope.uploader = new FileUploader({});
	$scope.import = function() {
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			$scope.uploadResponse = false;
			Flash.clear()
			Fan.import({
				id: $stateParams.id,
			},fd).$promise.then(function(fan){
				var message = "Successfully imported fan &quot;"+fan.name + "&quot";
				Flash.create('info', '<strong>Success!</strong> ' + message, 0, {}, true);
				// $scope.uploadResponse = res
				$scope.uploader.clearQueue()
				$scope.fan = fan;
				$scope.fanSpeedData = fan.fanSpeedData
				$scope.fanSpeedData.isCollapsed = true
				$scope.feiChartParams.presHigh = $scope.fan.DesSP
				if ($scope.configs.display_energySavings == 'true') {
					createEnergySavings()
				}
				getDependents()
				// $scope.apply()
			}).catch(function(e){
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.uploader.clearQueue()
			})
		});
	}

	if ($stateParams.id == "new") {
		if ($stateParams.copyId){
			Fan.get({
				id: $stateParams.copyId,
			}).$promise.then(function(fan) {
				$scope.fan = _.clone(_.omit(fan, "_id", "__v","$promise","$resolved"));
				$scope.ctx.formChanged = true;
				if ($scope.configs.display_energySavings == 'true') {
					createEnergySavings()
				}
				getDependents()
			})
			$scope.itemCodeDisabled = false
		} else {
			$scope.fan = {
				calculationType: 'equation'
			}
			if ($scope.configs.display_energySavings == 'true') {
				createEnergySavings()
			}
			getDependents()
		}
	} else {
		Fan.get({
			id: $stateParams.id,
		}).$promise.then(function(fan) {
			$scope.fan = fan;
			$scope.fanSpeedData = fan.fanSpeedData
			$scope.feiChartParams.presHigh = $scope.fan.DesSP
			if ($scope.configs.display_energySavings == 'true') {
				createEnergySavings()
			}
			getDependents()
		})
	}

	$scope.fanSpeedData = [];
	$scope.addFanSpeedData = function(fanSpeed, e) {
		e.preventDefault()
		$scope.ctx.formChanged = true
		if (fanSpeed && $scope.fanSpeedData.length < 3) {
			var defaultRows = []
			if ($scope.fanSpeedData[0]) {
				$scope.fanSpeedData[0].rows.forEach(function(row){
					defaultRows.push(['', '', '', '', ''])
				})
			} else {
				for (var i = 0; i < 10; i++) { // 10 default rows
					defaultRows.push(['', '', '', '', ''])
				}
			}

			$scope.fanSpeedData.push({
				speed: fanSpeed,
				rows: defaultRows
			});
		}
	};

	$scope.updateSpeed = function(fanSpeed, index) {
	    $scope.fanSpeedData[index].speed = fanSpeed.speed;
	  	$scope.ctx.formChanged = true
	};

	$scope.addRow = function(e) {
		e.preventDefault()
		$scope.ctx.formChanged = true
		$scope.fanSpeedData.forEach(function(fsd){
			fsd.rows.push(['', '', '', '', ''])
		})
	};
	$scope.removeRow = function(e) {
		e.preventDefault();
		$scope.ctx.formChanged = true
		$scope.fanSpeedData.forEach(function(fsd) {
			if (fsd.rows.length > 1) {
				fsd.rows.pop();
			}
		});
	};

	$scope.removeFanSpeed = function(index) {
		$scope.ctx.formChanged = true
		$scope.fanSpeedData.splice(index, 1);
	};

	$scope.updateCell = function(fanSpeed, rowIndex, cellIndex, event) {
	    var cellContent = Number(event.target.innerText);
	    if (fanSpeed && fanSpeed.rows && fanSpeed.rows[rowIndex]) {
	        fanSpeed.rows[rowIndex][cellIndex] = cellContent;
	        $scope.ctx.formChanged = true;
	    }
	};

	$scope.handleKeydown = function(event, fanSpeed, rowIndex, cellIndex) {
	    if (event.keyCode === 13) {
	        event.preventDefault();
	        var nextRowIndex = rowIndex + 1;
	        if (nextRowIndex < fanSpeed.rows.length) {
	            var editableCells = angular.element(document.querySelectorAll('td.editable-cell'));
	            var currentGlobalIndex = editableCells.index(event.target);
	            var nextGlobalIndex = currentGlobalIndex + fanSpeed.rows[0].length;
	            var nextCell = editableCells[nextGlobalIndex];
	            if (nextCell) {
	                nextCell.focus();
	            }
	        }
	    }
	};



	function createEnergySavings() {
		if (!$scope.fan.energySavings) {
			$scope.fan.energySavings = {
				fanMotorOutputHP:0,
				fanMotorOutputKW: 0,
				fixedSpeedInductionMotorEfficiency: 0,
				totalKwhPerYear: 0, //computed
				annualOperatingCost: 0,//computed
				fixSpeedInputKW: 0,
				stats: []
			}
		}
		var energySavings = $scope.fan.energySavings
		_.each([{
			percentRatedRpm:1,
			motorEfficiency: 0.77,
			percentRunTime: .015,
			timeHoursPerYear:0, //computed
			outputKW: 0, //computed
			inputKW: 0, //computed
			kWperYear: 0, //computed,
		},{
			percentRatedRpm:.75,
			motorEfficiency: 0.77,
			percentRunTime: .039,
			timeHoursPerYear:0, //computed
			outputKW: 0, //computed
			inputKW: 0, //computed
			kWperYear: 0, //computed,
		},{
			percentRatedRpm:0.5,
			motorEfficiency: 0.77,
			percentRunTime: .086,
			timeHoursPerYear:0, //computed
			outputKW: 0, //computed
			inputKW: 0, //computed
			kWperYear: 0, //computed,
		},{
			percentRatedRpm:.25,
			motorEfficiency: 0.77,
			percentRunTime: .167,
			timeHoursPerYear:0, //computed
			outputKW: 0, //computed
			inputKW: 0, //computed
			kWperYear: 0, //computed,
		},{
			percentRatedRpm:.12,
			motorEfficiency: .77,
			percentRunTime: .693,
			timeHoursPerYear:0, //computed
			outputKW: 0, //computed
			inputKW: 0, //computed
			kWperYear: 0, //computed,
		}], function(defaultObject){
			var item = _.find(energySavings.stats, function(o){
				return defaultObject.percentRatedRpm == o.percentRatedRpm
			})
			if (!item) {
				energySavings.stats.push(defaultObject)
			}
		})
	}

	$scope.feiCalcTest = {
		cfm: 0,
		ps: 0,
		zeroAs: 0.0001
	}

	$scope.runFEIErrorMessage
	$scope.runFEITest = function(e) {
		e.preventDefault()
		$scope.runFEIErrorMessage = ""
		$scope.isLoading = true;
		var cfm = $scope.feiCalcTest.cfm
		var ps = $scope.feiCalcTest.ps
		var fanId = $stateParams.id
		var zeroAs = $scope.feiCalcTest.zeroAs
		if (!fanId || $scope.fan.fanSpeedData.length == 0) {
			$scope.runFEIErrorMessage = "Missing fan or necessary fan speed data."
			return null
		}

		Fan.calculateSingleFEIRun({
			cfm: cfm,
			ps: ps,
			fanId: fanId,
			zeroAs: zeroAs
		}).$promise.then(function(res) {
			$scope.calculatedFEI = Number(res.fei.toFixed(2))
			$scope.isLoading = false;
		}).catch(function(e) {
			console.log('issue', e)
			$scope.isLoading = false;
		})
	}

	$scope.feiChartParams = {
		cfmSteps: 100, // example value
		presSteps: 10, // example value
		presLow: 0, // example value
		feiVals: '', // example value
		feiThreshold: 0.01, // example value	
		fanId: null
	}

	 $scope.$watch('feiChartParams.feiVals', function(newValue, oldValue) {
		if (newValue !== oldValue) {
			var values = newValue.split(',').map(function(item) {
				return parseFloat(item.trim()); // Convert each item to a float
			});
			$scope.feiChartParams.feiValsArray = values;
		}
	});

	$scope.isLoading = false;
	var colors = ['#29CF00', '#FF5733', '#3399FF', '#FFD700', '#7F00FF', '#FF007F', '#00FFFF', '#FF6600', '#6600FF', '#00FF00'];


	$scope.errorMessage = ""


	$scope.getFEIChartsExport = function(e, format) {
		var feiChartParams = $scope.feiChartParams
		const query = ["format=" + format]

		_.each(feiChartParams, function(value, key) {
			if (value === null || value === '') {
				//ignore
			} else if (_.isArray(value)) {
				const values = _.map(value, function(o){
					return o.toString()
				})
				query.push(key + "=" + values.join(","))
			} else {
				query.push(key + "=" + value)
			}
		})
		var url = "/api/fans/" + $stateParams.id + "/fei-charts/export?" + query.join("&");
		console.log("url", url)
		window.location.href = url;
	}

	$scope.getFEITableDataExport = function(e, format) {
	    var feiTableData = $scope.chartData;
	    var csvContent = "data:text/csv;charset=utf-8,";
		
		Object.entries(feiTableData).forEach(function(entry, index) {
		    var key = entry[0];
		    var coordsArray = entry[1];
		    if (!key.startsWith('$')) {
		        if (index > 0) {
		            csvContent += '\n';
		        }
		        csvContent += 'FEI Value = ' + key + '\n';
		        csvContent += 'CFM,PS\n';

		        coordsArray.forEach(function(coords) {
		            if (coords.x !== undefined && coords.y !== undefined) {
		                var row = coords.x + ',' + coords.y;
		                csvContent += row + '\n';
		            }
		        });
		    }
		});

	    var docName = $scope.fan.name + "_fei_table_data.csv"

	    var encodedUri = encodeURI(csvContent);
	    var link = document.createElement('a');
	    link.setAttribute('href', encodedUri);
	    link.setAttribute('download', docName);
	    
	    document.body.appendChild(link);
	    link.click();
	    document.body.removeChild(link);
	};

	$scope.getFEICharts = function(e) {
		e.preventDefault()
		$scope.calculationPending = true;
		$scope.chartData = undefined
		$scope.n3ChartData = [];
		$scope.options = {
			series: [],
		};


		if ($scope.fan.fanSpeedData.length == 0) {
			$scope.isLoading = false
			$scope.errorMessage = "This function requires data to be entered into the Fan Speed Data charts."
			return null
		}

		$scope.isLoading = true;

		var params = $scope.feiChartParams
		if ($stateParams.copyId) {
			params.fanId = $stateParams.copyId
		} else {
			params.fanId = $stateParams.id
		}

		Fan.getFEICharts(params).$promise.then(function(res) {
			$scope.chartData = res
			var mergedData = {};

			Object.keys(res).forEach(function(key, index) {
				if (Array.isArray(res[key])) {
					res[key].forEach(function(point) {
						if (point && typeof point === 'object' && 'x' in point && 'y' in point) {
							if (!mergedData[point.x]) {
								mergedData[point.x] = { x: point.x };
							}
							mergedData[point.x]['val_' + index] = point.y;
						}
					});

					var seriesConfig = {
						y: 'val_' + index,
						label: 'FEI = ' + key,
						color: colors[index], 
						type: 'line',
						id: 'series' + index,
						visible: true,
						drawDots: true,
						dotSize: '5px',
						thickness: '0px',
					};
					$scope.options.series.push(seriesConfig);
				}
			});

			// Convert the merged data map to an array and fill missing values
			for (var x in mergedData) {
				var point = mergedData[x];
				$scope.n3ChartData.push(point);
			}
		}).catch(function(error) {
			console.error('ERROR IN GETFEICHARTS', error);
		}).finally(function() {
			$scope.isLoading = false; // Stop loading when done
		});
	};
	
	function getDependents() {
		FanCategory.query({}).$promise.then(function(fanCategories){
			$scope.fanCategories = _.map(fanCategories, function(fanCategory){
				var existing = _.find($scope.fan.fanCategories, function(f){
					return f._id == fanCategory._id
				})
				if (existing){
					fanCategory.selected = true
				}
				return fanCategory
			});
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.fan);
			payload = _.omit(payload, "__v");
			payload.fanCategories = _.pluck(_.filter($scope.fanCategories, function(fanCategory){
				return fanCategory.selected
			}), "_id")
			payload.fanSpeedData = $scope.fanSpeedData
			if (!$scope.fan._id) {
				Fan.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					$scope.fan = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created fan.', 0, {}, true);
					$state.go("fan", {
						id: result._id,
						copyId: null
					}, {
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				Fan.update({
					id: $scope.fan._id
				}, payload).$promise.then(function(fan) {
					
					$scope.success = true;
					$scope.fan = fan;
					Flash.create('success', '<strong>Success!</strong> Successfully updated fan.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
